import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  @Output() toggleMenuEvent = new EventEmitter();

  toggleMenu(): void {
    this.toggleMenuEvent.emit();
  }
}
