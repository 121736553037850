<div *ngIf="isMobile" class="exit-position">
  <div class="icon-container exit" (click)="toggleMenu()">
    <span class="iconify" data-icon="ic:baseline-close" data-inline="true"></span>
  </div>
</div>

<div class="sidenav-container enable-vert-scroll" (swipeleft)="toggleMenu()">
  <div class="header">
    <app-header (toggleMenuEvent)="toggleMenu()"></app-header>
  </div>
  <div class="divider">
    <hr>
  </div>
  <div class="nav">
    <app-nav></app-nav>
  </div>
  <div class="divider">
    <hr>
  </div>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</div>
