<div class="footer-flex">
  <div class="icon-container">
    <a href="https://www.linkedin.com/in/caleb-devonport/"><span class="iconify" data-icon="mdi-linkedin" data-inline="true"></span></a>
  </div>
  <div class="icon-container">
    <a href="https://github.com/CalebDevonport"><span class="iconify" data-icon="mdi-github" data-inline="true"></span></a>
  </div>
  <div class="icon-container">
    <a href="mailto:calebdevonport@outlook.com"><span class="iconify" data-icon="mdi-email" data-inline="true"></span></a>
  </div>
</div>
