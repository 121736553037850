import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-routing',
  templateUrl: './blog-routing.component.html'
})
export class BlogRoutingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
