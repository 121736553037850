import { Component } from '@angular/core';

@Component({
  selector: 'app-reading-list-twenty-one',
  templateUrl: './reading-list-twenty-one.component.html',
  styleUrls: ['./reading-list-twenty-one.component.scss']
})
export class ReadingListTwentyOneComponent {

  constructor() { }

  ngOnInit(): void {
  }

}
