import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-game-reviews-routing',
  templateUrl: './game-reviews-routing.component.html'
})
export class GameReviewsRoutingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
