<div *ngIf="open" [@navAnimation] class="nav-container" (swipedown)="onSwipeDown()">
  <div class="icon-container exit" (click)="closeMenu()">
    <span class="iconify" data-icon="ic:baseline-close" data-inline="true"></span>
  </div>
  <div class="spacer"></div>
  <div class="aligner">
    <ul class="links">
      <li>
        <a [routerLink]="['/', AppRoutes.Home]">
          <h4 class="is-active">Home</h4>
        </a>
      </li>
      <!-- <li>
        <a (click)="navigate(AppRoutes.CV)" [routerLink]="['/', AppRoutes.CV]">
          <h4>CV</h4>
        </a>
      </li>
      <li>
        <a (click)="navigate(AppRoutes.Portfolio)" [routerLink]="['/', AppRoutes.Portfolio]">
          <h4>Portfolio</h4>
        </a>
      </li> -->
      <li>
        <a (click)="navigate(AppRoutes.Blog)" [routerLink]="['/', AppRoutes.Blog]">
          <h4>Blog</h4>
        </a>
      </li>
      <li>
        <a (click)="navigate(AppRoutes.GameReviews)" [routerLink]="['/', AppRoutes.GameReviews]">
          <h4>Game Reviews</h4>
        </a>
      </li>
      <li>
        <a (click)="navigate(AppRoutes.Contact)" [routerLink]="['/', AppRoutes.Contact]">
          <h4>Contact</h4>
        </a>
      </li>
    </ul>
  </div>
  <div class="spacer"></div>
</div>
<div *ngIf="!open" class="icon-container" (click)="openMenu()" >
  <span class="iconify" data-icon="ic:baseline-expand-less" data-inline="true"></span>
</div>

<div *ngIf="open" class="icon-container" (click)="closeMenu()">
  <span class="iconify" data-icon="ic:baseline-expand-more" data-inline="true"></span>
</div>
  