<div class="whole-page">
  <mat-sidenav-container>
    <mat-sidenav *ngIf="!isHome" #sidenav [opened]="opened" [mode]="mode">
      <app-sidenav (toggleMenuEvent)="toggleMenu()"></app-sidenav>
    </mat-sidenav>
    <mat-sidenav-content [ngClass]="{'enable-touch': !isHome}">
      <app-toolbar *ngIf="!isHome && isMobile" (toggleMenuEvent)="toggleMenu()"></app-toolbar>
      <div [ngClass]="{'mobile-padding': !isHome}" class="main-container">
        <div class="border"></div>
        <div class="content-container" #content [ngStyle]="{'max-width': contentWidth}">
          <router-outlet></router-outlet>
        </div>
        <div class="border"></div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
