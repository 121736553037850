<div class="outer-aligner">
  <div class="spacer"></div>
  <div>
    <div>
      <h1>Get in touch!</h1>
      <h4>If you'd like to contact me then feel free to send me an email at <a href="mailto:calebdevonport@outlook.com">calebdevonport@outlook.com</a>, 
        or message me on <a href="https://www.linkedin.com/in/caleb-devonport/">LinkedIn.</a></h4>
      <h4>Hope to hear from you soon!</h4>
    </div>
  </div>
  <div class="spacer"></div>
</div>
