<div class="outer-aligner" (swipeup)="onSwipeUp()">
  <div class="spacer"></div>
  <div class="aligner" #aligner>
    <div class="bio">
      <div>
        <h1>Hi. I'm Caleb.</h1>
        <h4>I'm a full stack software engineer working primarily with .Net and Angular applications. I live in St Albans and work in London. </h4>
        <h4>I like gaming, reading, various other things, and I'm a little bit obsessed with coffee.</h4>
        <h4>Welcome to my website.</h4>
      </div>
    </div>
    <div class="spacer" *ngIf="!isMobile"></div>
    <div class="portrait" #portrait style="max-width: 80%;">
      <img fetchpriority="high" src="assets/images/Portrait.jpg">
    </div>
    <div class="icon-container">
      <app-home-nav *ngIf="isMobile" [openMenuSubject]="openMenuSubject.asObservable()"></app-home-nav>
    </div>
  </div>
  <ul class="links" *ngIf="!isMobile">
    <li class="list-item">
      <a [routerLink]="['/', AppRoutes.Home]">
        <h4 class="is-active">Home</h4>
      </a>
    </li>
    <!-- <li class="list-item">
      <a [routerLink]="['/', AppRoutes.CV]">
        <h4>CV</h4>
      </a>
    </li>
    <li class="list-item">
      <a [routerLink]="['/', AppRoutes.Portfolio]">
        <h4>Portfolio</h4>
      </a>
    </li> -->
    <li class="list-item">
      <a [routerLink]="['/', AppRoutes.Blog]">
        <h4>Blog</h4>
      </a>
    </li>
    <li class="list-item">
      <a [routerLink]="['/', AppRoutes.GameReviews]">
        <h4>Game Reviews</h4>
      </a>
    </li>
    <li class="list-item">
      <a [routerLink]="['/', AppRoutes.Contact]">
        <h4>Contact</h4>
      </a>
    </li>
  </ul>
  <div class="spacer"></div>
</div>
