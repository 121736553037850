<div>
  <nav>
    <ul>
      <li>
        <a routerLink="{{AppRoutes.Home}}" routerLinkActive #homeActive="routerLinkActive">
          <h2 [ngClass]="{'is-active': homeActive.isActive}">Home</h2>
        </a>
      </li>
      <!-- <li>
        <a routerLink="{{AppRoutes.CV}}" routerLinkActive #cvActive="routerLinkActive">
          <h2 [ngClass]="{'is-active': cvActive.isActive}">CV</h2>
        </a>
      </li>
      <li>
        <a routerLink="{{AppRoutes.Portfolio}}" routerLinkActive #PortfolioActive="routerLinkActive">
          <h2 [ngClass]="{'is-active': PortfolioActive.isActive}">Portfolio</h2>
        </a>
      </li> -->
      <li>
        <div class="flex-container">
          <div style="flex:1;"></div>
          <div style="flex:100;">
            <a routerLink="{{AppRoutes.Blog}}" routerLinkActive #blogActive="routerLinkActive">
              <h2 [ngClass]="{'is-active': blogActive.isActive}">Blog</h2>
            </a>
          </div>
          <div style="flex:1;">
            <mat-icon (click)="toggleBlogSubList()" class="icon" >keyboard_arrow_down</mat-icon>
          </div>
        </div>
        <ul *ngIf="blogSubListOpen">
          <li>
            <a routerLink="{{AppRoutes.Blog}}/{{AppRoutes.ReadingList2021}}" routerLinkActive #readingList2021Active="routerLinkActive">
              <h4 [ngClass]="{'is-active': readingList2021Active.isActive}">Reading List 2021</h4>
            </a>
          </li>
        </ul>
      </li>
      <li>
        <div class="flex-container">
          <div style="flex:1;"></div>
          <div style="flex:100;">
            <a routerLink="{{AppRoutes.GameReviews}}" routerLinkActive #gameReviewsActive="routerLinkActive">
              <h2 [ngClass]="{'is-active': gameReviewsActive.isActive}">Game reviews</h2>
            </a>
          </div>
          <div style="flex:1;">
            <mat-icon (click)="toggleReviewSubList()" class="icon" >keyboard_arrow_down</mat-icon>
          </div>
        </div>
        <ul *ngIf="reviewSubListOpen">
          <li>
            <a routerLink="{{AppRoutes.GameReviews}}/{{AppRoutes.TheCallistoProtocol}}" routerLinkActive #theCallistoProtocolActive="routerLinkActive">
              <h4 [ngClass]="{'is-active': theCallistoProtocolActive.isActive}">The Callisto Protocol</h4>
            </a>
          </li>
          <li>
            <a routerLink="{{AppRoutes.GameReviews}}/{{AppRoutes.Scorn}}" routerLinkActive #scornActive="routerLinkActive">
              <h4 [ngClass]="{'is-active': scornActive.isActive}">Scorn</h4>
            </a>
          </li>
          <li>
            <a routerLink="{{AppRoutes.GameReviews}}/{{AppRoutes.DestinyWitchQueen}}" routerLinkActive #destinyWitchQueenActive="routerLinkActive">
              <h4 [ngClass]="{'is-active': destinyWitchQueenActive.isActive}">Destiny 2: The Witch Queen</h4>
            </a>
          </li>
          <li>
            <a routerLink="{{AppRoutes.GameReviews}}/{{AppRoutes.LittleNightmares2}}" routerLinkActive #littleNightmaresTwoActive="routerLinkActive">
              <h4 [ngClass]="{'is-active': littleNightmaresTwoActive.isActive}">Little Nightmares 2</h4>
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a routerLink="{{AppRoutes.Contact}}" routerLinkActive #contactActive="routerLinkActive">
          <h2 [ngClass]="{'is-active': contactActive.isActive}">Contact</h2>
        </a>
      </li>
    </ul>
  </nav>
</div>
